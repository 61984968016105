@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
* {
    font-family: 'Source Sans 3';
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --azul-oscuro: #313e48;
    --azul: #238dc0;
    --morado: #7f54b3;
    --blanco: #fff;
    --site_width: 1400px;
    --padding_vertical: 30px;
}

.site-width {
    max-width: var(--site_width);
    margin: auto;
    padding-top: var(--padding_vertical);
    padding-bottom: var(--padding_vertical);
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

header img {
    width: 170px;
}

.login {
    gap: 20px;
    height: 70vh;
    max-width: var(--site_width);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login form {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
}

.intro-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin-bottom: 20px;
}

.intro-login img {
    width: 60px;
}

.tool-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    margin-bottom: 20px;
}

.tool-logo h1 {
    font-size: 30px;
}

input {
    outline: none;
}

h1 {
    color: var(--azul-oscuro);
    font-weight: 900;
    font-size: 25px;
}

h2 {
    color: var(--azul-oscuro);
    font-size: 23px;
}

h3 {
    color: var(--azul-oscuro);
}

p {
    font-size: 15px;
    color: #878787;
}

.intro-login p {
    color: #878787;
}

.input-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login form button {
    background-color: var(--azul);
    color: var(--blanco);
    border: 0px;
    padding: 7px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.input-data {
    position: relative;
}

.input-data label {
    color: var(--azul) !important;
    font-weight: 600;
    font-size: 15px;
    position: absolute;
    top: -10px;
    left: 5px;
    background-color: var(--blanco);
    padding-left: 10px;
    padding-right: 10px;
}

.error {
    color: #e74c3c;
    background-color: #fadedb;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
}

.input-data input {
    border: 1px solid #0000001b;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* Dashboard */

.tabla-wooexport {
    margin-top: 35px;
}

.grid-inserciones {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 0.2fr;
    gap: 35px;
}

.insercion {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.insercion button {
    border: 2px solid var(--azul);
    padding: 5px;
    background-color: var(--blanco);
    color: var(--azul);
    font-weight: 600;
    transition: all 0.3s;
    cursor: pointer;
}

.insercion button:hover {
    background-color: var(--azul);
    color: var(--blanco);
}

.eliminar-filas button {
    border: 2px solid #e74c3c;
    padding: 5px;
    background-color: var(--blanco);
    color: #e74c3c;
    font-weight: 600;
    transition: all 0.3s;
    cursor: pointer;
}

.eliminar-filas button:hover {
    background-color: #e74c3c;
    color: var(--blanco);
}

.overflow-woo {
    overflow: auto;
    border-radius: 8px;
    overflow-x: scroll;
    margin-top: 20px;
    min-height: 200px;
    background-color: #c5eafb;
}

.wooexport-intro {
    margin-top: 25px;
}

.wootable {
    width: 100%;
    max-width: var(--site_width);
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-x: scroll;
}

.wootable thead>tr th:first-child {
    min-width: 100px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 999999999;
    top: 125%;
    /* Cambia según donde quieras mostrar el tooltip */
    left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
    white-space: normal;
    /* Permite que el texto se divida en varias líneas */
    overflow-wrap: break-word;
    /* Rompe las palabras largas para evitar el desbordamiento */
    word-break: break-word;
    /* Otra opción para romper palabras largas */
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.wootable thead>tr th>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.wootable thead>tr th>div img {
    width: 16px;
}

.wootable thead>tr th {
    background-color: var(--azul-oscuro);
    color: var(--blanco);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    min-width: 200px;
}

.wootable thead>tr th:nth-child(2n) {
    background-color: var(--azul);
}

.wootable th {
    padding: 12px 15px;
}

.wootable td {
    padding: 12px 15px;
}

.wootable th {
    font-weight: 600;
}

.wootable tbody tr {
    border-bottom: 1px solid #dddddd;
    transition: background-color 0.2s ease;
}

.wootable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.wootable tbody tr:hover {
    background-color: #c5eafb;
}

.wootable tbody tr:last-of-type {
    border-bottom: 2px solid var(--azul);
}

.wootable tbody td input[type="text"] {
    width: 200px;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.wootable tbody td button {
    padding: 6px 12px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.wootable tbody td button:hover {
    background-color: #ff1a1a;
}

.wootable tfoot {
    background-color: #f3f3f3;
    text-align: right;
    padding: 10px;
}

.csv-export {
    background-color: #27ae60;
    color: var(--blanco);
    font-weight: 600;
    border: 0px;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}


/* Admin */

.boton-admin {
    background-color: #27ae60;
    border: 0px;
    color: var(--blanco);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 20px;
    border-radius: 5px;
}

.boton-logout {
    background-color: #e74c3c;
    border: 0px;
    color: var(--blanco);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 20px;
    border-radius: 5px;
    margin-left: 10px;
}

.usuario-desactivado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50vh;
    gap: 20px;
}

.usuario-desactivado img {
    width: 60px;
    opacity: 0.7;
}

.intro-admin {
    margin-bottom: 25px;
}

.grid-administracion {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: 35px;
}

.grid-administracion form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
}

.grid-administracion form input,
.grid-administracion form select {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.grid-administracion form button {
    background-color: var(--azul);
    color: var(--blanco);
    padding: 5px;
    border: 0px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}

.input-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-form label {
    color: var(--azul);
    font-weight: 500;
    font-size: 15px;
}

.lista-usuarios {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lista-usuarios input {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border: 0px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    font-size: 14px;
}

.grid-usuarios {
    margin-top: 20px;
    display: grid;
    gap: 10px;
    max-height: 600px;
    overflow-y: auto;
}

.usuario-listado {
    background-color: #c5eafb;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.usuario-listado p {
    color: var(--azul-oscuro);
    font-weight: 500;
}

.usuario-listado.admin {
    background-color: var(--azul);
}

.usuario-listado.admin p {
    color: var(--blanco);
    font-weight: 600;
}

.usuario-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.usuario-actions button {
    background-color: #e74c3c;
    padding: 3px;
    border: 0px;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--blanco);
    border-radius: 5px;
    cursor: pointer;
}

.usuario-actions select {
    background-color: #fff;
    padding: 3px;
    border: 0px;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--azul-oscuro);
    border-radius: 5px;
}


/* Loader */

.loader-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}


/* From Uiverse.io by JaydipPrajapati1910 */

.loader {
    display: block;
    --height-of-loader: 4px;
    --loader-color: var(--azul);
    width: 130px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
}

.loader::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
}

@keyframes moving {
    50% {
        width: 100%;
    }
    100% {
        width: 0;
        right: 0;
        left: unset;
    }
}

@media only screen and (max-width: 1450px) {
    .site-width {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 1400px) {
    .grid-inserciones {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 35px;
    }
}

@media only screen and (max-width: 1100px) {
    .contenido-generador {
        grid-template-columns: 1fr;
    }
    .grid-secciones>div {
        max-height: 200px;
        overflow-y: auto;
    }
    .grid-secciones {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 1000px) {
    .grid-administracion {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 700px) {
    .grid-secciones {
        grid-template-columns: 1fr;
    }
    h1 {
        color: var(--azul-oscuro);
        font-weight: 900;
        font-size: 22px;
    }
    h2 {
        color: var(--azul-oscuro);
        font-size: 20px;
    }
    .secciones-container h2 {
        text-align: center;
    }
    h3 {
        color: var(--azul-oscuro);
    }
    p {
        font-size: 15px;
        color: #878787;
    }
    .login form {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 30px;
        border-radius: 5px;
        width: 300px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;
    }
    .grid-inserciones {
        display: grid;
        grid-template-columns: 1fr;
        gap: 35px;
    }
}

@media only screen and (max-width: 500px) {
    .login form {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 30px;
        border-radius: 5px;
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;
    }
}